import uae from './uae'
import thailand from './thailand'
import turkey from './turkey'
import indonesia from './indonesia'
import japan from './japan'

export default {
  'uae': uae,
  'thailand': thailand,
  'turkey': turkey,
  'indonesia': indonesia,
  'japan': japan
}