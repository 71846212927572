import dubai from './uae/dubai'
import bangkok from './thailand/bangkok'
import phuket from './thailand/phuket'
import kosamui from './thailand/kosamui'
import istanbul from './turkey/istanbul'
import bali from './indonesia/bali'
import tokyo from './japan/tokyo'

export default {
  'uae-dubai': dubai,
  'thailand-phuket': phuket,
  'thailand-bangkok': bangkok,
  'thailand-kosamui': kosamui,
  'turkey-istanbul': istanbul,
  'indonesia-bali': bali,
  'japan-tokyo': tokyo
}