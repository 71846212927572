import React, { useState } from 'react'

import { Grid, FormControl, TextField, FormHelperText, Button, FormControlLabel, Checkbox, Hidden } from '@material-ui/core'

import axios from 'axios'

import arrowDown from '../../assets/img/arrow-down.svg'

const testFunctions = {
  firstName: (val) => val && /^[a-zA-Z ]{3,50}$/g.test(val),
  lastName: (val) => val && /^[a-zA-Z ]{3,50}$/g.test(val),
  mail: (val) => val && /^[a-zA-Z0-9+_.~-]{3,50}[@][a-zA-Z0-9+_.~-]{3,50}[.][a-zA-Z]{2,10}$/g.test(val),
  phone: (val) => val && /^[0]{1}[67]{1}[0-9]{8}$/g.test(val),
  passportNumber: (val) => val && /^[0-9]{2}[A-Za-z]{2}[0-9]{5}$/g.test(val),
  passportExp: (val, end) => {
    if (val){
      const endDate = end
      const valDate = new Date(val)
      valDate.setMonth(valDate.getMonth() - 6)
      return valDate.getTime() > endDate.getTime()
    }
    return false
  },
  birthday: (val) => {
    if (val){
      const tmpDate = new Date()
      const valDate = new Date(val)
      tmpDate.setFullYear(tmpDate.getFullYear() - 18)
      return valDate.getTime() < tmpDate.getTime()
    }
    return false
  },
  depart: (val) => val && ['paris', 'lyon', 'lille', 'marseille', 'toulouse', 'bordeaux', 'nantes', 'montpellier'].indexOf(val) !== -1
}

const ParticipantForm = ({ title, datas }) => {
  return (
    <Grid container spacing={3} style={{marginBottom: 30}}>
      <Grid item xs={12}>
        <p style={{fontSize: 20, fontWeight: 'bold'}}>{title}</p>
      </Grid>
      {datas ? datas.map(data => <Grid key={data.label} item xs={12} md={6} lg={4}>
        <FormControl>
          <TextField
            error={data.error}
            id={data.id}
            label={data.label} 
            type={data.type}
            select={data.type === 'select'}
            variant="outlined" 
            value={data.value} 
            onChange={data.onChange} 
            required
            InputLabelProps={data.type === 'date' ? {shrink: true} : {}} 
            SelectProps={data.type === 'select' ? {native: true} : {}}
          >
            {data.type === 'select' && data.options ? data.options.map(option => <option key={option.id} value={option.id}>
              {option.label}
            </option>) : ''}
          </TextField>
          {data.error && !data.test(data.value) ? <FormHelperText>{data.value ? data.errorMsg : data.errorMsgEmpty}</FormHelperText> : ''}
        </FormControl>
      </Grid>): ''}
    </Grid>
  )
}

const Step4 = ({ backButton, updateValue, stay, city, stayId, end }) => {  
  const [firstName1, setFirstName1] = useState(null)
  const [lastName1, setLastName1] = useState(null)
  const [mail1, setMail1] = useState(null)
  const [phone1, setPhone1] = useState(null)
  const [passportNumber1, setPassportNumber1] = useState(null)
  const [passportExp1, setPassportExp1] = useState(null)
  const [birthday1, setBirthday1] = useState(null)
  const [depart1, setDepart1] = useState('paris')

  const [errorFirstName1, setErrorFirstName1] = useState(false)
  const [errorLastName1, setErrorLastName1] = useState(false)
  const [errorMail1, setErrorMail1] = useState(false)
  const [errorPhone1, setErrorPhone1] = useState(false)
  const [errorPassportNumber1, setErrorPassportNumber1] = useState(false)
  const [errorPassportExp1, setErrorPassportExp1] = useState(false)
  const [errorBirthday1, setErrorBirthday1] = useState(false)
  const [errorDepart1, setErrorDepart1] = useState(false)

  const participant1 = [
    {
      id: 'firstName',
      label: 'Prénom',
      value: firstName1,
      test: testFunctions.firstName,
      onChange: (e) => {setFirstName1(e.currentTarget.value)},
      error: errorFirstName1,
      setError: setErrorFirstName1,
      errorMsgEmpty: "Il faut que tu indiques ton prénom 🙂",
      errorMsg: "Il ne semble pas être au bon format 🙃 (ex: John)"
    },
    {
      id: 'lastName',
      label: 'Nom',
      value: lastName1,
      test: testFunctions.lastName,
      onChange: (e) => {setLastName1(e.currentTarget.value)},
      error: errorLastName1,
      setError: setErrorLastName1,
      errorMsgEmpty: "Il faut que tu indiques ton nom 🙂",
      errorMsg: "Il ne semble pas être au bon format 🙃 (ex: Doe)"
    },
    {
      id: 'mail',
      label: 'Adresse e-mail',
      type: 'email',
      value: mail1,
      test: testFunctions.mail,
      onChange: (e) => {setMail1(e.currentTarget.value)},
      error: errorMail1,
      setError: setErrorMail1,
      errorMsgEmpty: "Il faut que tu indiques ton adresse e-mail 🙂",
      errorMsg: "Il ne semble pas être au bon format 🙃 (ex: john.doe@acme.com)"
    },
    {
      id: 'phone',
      label: 'Numéro de téléphone',
      type: 'tel',
      value: phone1,
      test: testFunctions.phone,
      onChange: (e) => {setPhone1(e.currentTarget.value)},
      error: errorPhone1,
      setError: setErrorPhone1,
      errorMsgEmpty: "Il faut que tu indiques ton numéro de téléphone 🙂",
      errorMsg: "Il ne semble pas être au bon format 🙃 (ex: 0600000000)"
    },
    {
      id: 'passportNumber',
      label: 'Numéro du passeport',
      value: passportNumber1,
      test: testFunctions.passportNumber,
      onChange: (e) => {setPassportNumber1(e.currentTarget.value)},
      error: errorPassportNumber1,
      setError: setErrorPassportNumber1,
      errorMsgEmpty: "Il faut que tu indiques le numéro de ton passeport 🙂",
      errorMsg: "Il ne semble pas être au bon format 🙃 (ex: 00AA00000)"
    },
    {
      id: 'passportExp',
      label: 'Date d\'expiration du passeport',
      type: 'date',
      value: passportExp1,
      test: (val) => testFunctions.passportExp(val, end),
      onChange: (e) => {setPassportExp1(e.currentTarget.value)},
      error: errorPassportExp1,
      setError: setErrorPassportExp1,
      errorMsgEmpty: "Il faut que tu indiques la date d'expiration de ton passeport 🙂",
      errorMsg: "Il faut que ton passeport expire plus de 6 mois après la date de retour 🙃"
    },
    {
      id: 'birthday',
      label: 'Date de naissance',
      type: 'date',
      value: birthday1,
      test: testFunctions.birthday,
      onChange: (e) => {setBirthday1(e.currentTarget.value)},
      error: errorBirthday1,
      setError: setErrorBirthday1,
      errorMsgEmpty: "Il faut que tu indiques ta date de naissance 🙂",
      errorMsg: "Désolé tu dois avoir + de 18 ans pour partir 🙃"
    },
    {
      id: 'depart',
      label: 'Aéroport de départ',
      type: 'select',
      options: [
        {
          id: 'paris',
          label: 'Paris'
        },
        {
          id: 'lyon',
          label: 'Lyon'
        },
        {
          id: 'lille',
          label: 'Lille'
        },
        {
          id: 'marseille',
          label: 'Marseille'
        },
        {
          id: 'toulouse',
          label: 'Toulouse'
        },
        {
          id: 'bordeaux',
          label: 'Bordeaux'
        },
        {
          id: 'nantes',
          label: 'Nantes'
        },
        {
          id: 'montpellier',
          label: 'Montpellier'
        }
      ],
      value: depart1,
      test: testFunctions.depart,
      onChange: (e) => {setDepart1(e.currentTarget.value)},
      error: errorDepart1,
      setError: setErrorDepart1,
      errorMsgEmpty: "Il faut que tu choisisses ton aéroport de départ 🙂",
      errorMsg: "Etrangement l'aéroport sélectionné n'est pas un de ceux dans la liste 🙃"
    }
  ]
  
  const [cgv, setCgv] = useState(false)
  const [cgvError, setCgvError] = useState(false)

  const [cancel, setCancel] = useState(false)
  const [cancelError, setCancelError] = useState(false)

  const onSubmitInfos = () => {
    let allowNext = cgv && cancel
    setCgvError(!cgv)
    setCancelError(!cancel)

    participant1.forEach(inp => {
      let passTest = inp.test(inp.value)
      inp.setError(!passTest)
      allowNext = allowNext && passTest
    })
    
    if (allowNext){
      const bodyData = {...Object.fromEntries(participant1.map(p => [p.id, p.value])), cgv, cancel, stayId}

      axios.post(`${process.env.GATSBY_API_URL}/participant`, bodyData, {
        headers: {
          CotravelingKey: process.env.GATSBY_API_CKEY
        }
      })
      .then(function (response) {
        updateValue(response.data.id)
      })
      .catch(function (error) {
        console.log(error)
      })
    }
  }

  return !stay || !city ? <div>Loading...</div> : (
    <div class="rform">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} style={{marginBottom: 20}}>
            <Grid item xs={12} md={4} lg={4} style={{marginTop: 10}}>
              <div class="single-page" style={{padding: '0px 20px'}}>
                <div class="single-sidebar">
                  <div class="tour--info">
                    <div class="top" style={{backgroundImage: `url(${stay.images.hotel[0]})`}}>
                      <div class="tags">
                      </div>
                      <div class="flex-bottom">
                        <div class="rating">
                          <div class="name">{stay.datas.starsName}</div>
                          <div class="stars">
                            {stay.datas.stars > 0 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 1 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 2 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 3 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 4 ? <div class="star active" /> : ''}
                          </div>
                        </div>
                        <div class="_title">{`${city.name}`}</div>
                        <div class="time">{stay.datas.days} jours | {stay.datas.nights} nuits</div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="cost" style={{marginBottom: 0}}>
                        <div class="new-cost" style={{display: 'flex', justifyContent: 'space-between'}}>
                          <span>{stay.datas.price} €</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} lg={8} style={{marginTop: 10}}>
              <ParticipantForm title={`Informations du participant`} datas={participant1} />
              <Grid container spacing={3} style={{marginBottom: 20}}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={cgv} onChange={(e) => setCgv(e.currentTarget.checked)} name="cgv" />}
                    label={<span>J'ai pris connaissance et j'accepte les <a target="_blank" referrer="no-referrer" href="/cgv" style={{color: '#00458b', textDecoration: 'underline'}}>conditions générales de ventes</a> du service de CoTraveling.</span>}
                  />
                  {cgvError && !cgv ? <FormHelperText>Il faut accepter les conditions</FormHelperText> : ''}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={cancel} onChange={(e) => setCancel(e.currentTarget.checked)} name="cancel" />}
                    label="Je reconnais que CoTraveling procédera à l'exécution immédiate des réservations relatives à la prestation et cela à compter de la validation de ma commande et à ce titre je renonce expressément à exercer mon droit de rétractation conformément aux dispositions de l'article L.221-28 1° du code de la consommation."
                  />
                  {cancelError && !cancel ? <FormHelperText>Il faut accepter cette mesure</FormHelperText> : ''}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl>
                    <Button onClick={backButton} variant="outlined" color="secondary" class="submitButton">
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                        <img src={arrowDown} alt='arrow-down' style={{transform: 'rotate(90deg)', width: 20, marginRight: 10}} />
                        <span>Choix de la date</span>
                      </div>
                    </Button>
                  </FormControl>
                </Grid>
                <Hidden mdDown>
                  <Grid item lg={4} />
                </Hidden>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl>
                    <Button onClick={onSubmitInfos} variant="contained" color="secondary" class="submitButton">
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                        <span>Paiement</span>
                        <img src={arrowDown} alt='arrow-down' style={{transform: 'rotate(-90deg)', width: 20, marginLeft: 10}} />
                      </div>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Step4
