import React from 'react'

import arrowDown from '../../assets/img/arrow-down.svg'

import staysSlug from '../../data/staysFull/staysSlug'

/*const Step2 = ({ backButton, updateValue, stays }) => {
  return (
    <div class="most_popular__section">
      <a class="slider_item rclear" type="btn" onClick={backButton} style={{
        background: '#ffb67f'
      }}>
        <div class="slider_item__content">
          <div class="title">
            Modifier la destination
            <div class="arrowdown"><img src={arrowDown} alt='arrow-down' style={{transform: 'rotate(90deg)'}} /></div>
          </div>
        </div>
      </a>
      {stays ? stays.map(stay => <a key={stay.id} class="slider_item" style={{backgroundImage: `url(${stay.images.hotel[0]})`}} type="btn" onClick={() => updateValue(stay.id)}>
        <div class="slider_item__content">
          <div class="title">{stay.name}</div>
          <div class="rfooter">
            <div class="rating">
              <div class="name">{stay.datas.starsName}</div>
              <div class="stars">
                {stay.datas.stars > 0 ? <div class="star active" /> : ''}
                {stay.datas.stars > 1 ? <div class="star active" /> : ''}
                {stay.datas.stars > 2 ? <div class="star active" /> : ''}
                {stay.datas.stars > 3 ? <div class="star active" /> : ''}
                {stay.datas.stars > 4 ? <div class="star active" /> : ''}
              </div>
              <div class="name" style={{position: 'absolute', right: 0, fontWeight: 'bold'}}>{stay.datas.price} €</div>
            </div>
          </div>
        </div>
      </a>) : ''}
    </div>
  )
}

export default Step2*/

const Step2 = ({ backButton, updateValue, myStayTemplates }) => {
  return (
    <div class="most_popular__section">
      <a class="slider_item rclear" type="btn" onClick={backButton} style={{
        background: '#ffb67f'
      }}>
        <div class="slider_item__content">
          <div class="title">
            Modifier la destination
            <div class="arrowdown"><img src={arrowDown} alt='arrow-down' style={{transform: 'rotate(90deg)'}} /></div>
          </div>
        </div>
      </a>
      {myStayTemplates ? myStayTemplates.map(myStayTemplate => {
        const stay = staysSlug[myStayTemplate.slug]
console.log(stay)
        return (
          <a key={stay.id} class="slider_item" style={{backgroundImage: `url(${stay.images.hotel[0]})`}} type="btn" onClick={() => updateValue(stay.slug)}>
            <div class="slider_item__content">
              <div class="title">{stay.name}</div>
              <div class="rfooter">
                <div class="rating">
                  <div class="name">{stay.datas.starsName}</div>
                  <div class="stars">
                    {stay.datas.stars > 0 ? <div class="star active" /> : ''}
                    {stay.datas.stars > 1 ? <div class="star active" /> : ''}
                    {stay.datas.stars > 2 ? <div class="star active" /> : ''}
                    {stay.datas.stars > 3 ? <div class="star active" /> : ''}
                    {stay.datas.stars > 4 ? <div class="star active" /> : ''}
                  </div>
                  <div class="name" style={{position: 'absolute', right: 0, fontWeight: 'bold'}}>{stay.datas.price} €</div>
                </div>
              </div>
            </div>
          </a>
        )
      }) : ''}
    </div>
  )
}

export default Step2
