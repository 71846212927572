import dubaiGold from './uae/dubai/gold'
import dubaiPlatinium from './uae/dubai/platinium'
import phuketPlatinium from './thailand/phuket/platinium'
import phuketDiamond from './thailand/phuket/diamond'
import bangkokGold from './thailand/bangkok/gold'
import bangkokPlatinium from './thailand/bangkok/platinium'
import kosamuiGold from './thailand/kosamui/gold'
import istanbulGold from './turkey/istanbul/gold'
import istanbulPlatinium from './turkey/istanbul/platinium'
import tokyoGold from './japan/tokyo/gold'
import baliGold from './indonesia/bali/gold'
import baliPlatinium from './indonesia/bali/platinium'
import baliDiamond from './indonesia/bali/diamond'

export default {
  'uae-dubai-gold': dubaiGold,
  'uae-dubai-platinium': dubaiPlatinium,
  'thailand-phuket-platinium': phuketPlatinium,
  'thailand-phuket-diamond': phuketDiamond,
  'thailand-bangkok-gold': bangkokGold,
  'thailand-bangkok-platinium': bangkokPlatinium,
  'thailand-kosamui-gold': kosamuiGold,
  'turkey-istanbul-gold': istanbulGold,
  'turkey-istanbul-platinium': istanbulPlatinium,
  'japan-tokyo-gold': tokyoGold,
  'indonesia-baligold': baliGold,
  'indonesia-baliplatinium': baliPlatinium,
  'indonesia-bali-diamond': baliDiamond,
}