import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"
import "../assets/scss/tour-list.css"

import axios from 'axios'

import { Stepper } from 'react-form-stepper'

import Step1 from '../components/reservations/step1'
import Step2 from '../components/reservations/step2'
import Step3 from '../components/reservations/step3'
import Step4 from '../components/reservations/step4'
import Step5 from '../components/reservations/step5'
import Step6 from '../components/reservations/step6'

import companyDatas from '../data/company'

import citiesSlug from '../data/staysFull/citiesSlug'
import staysSlug from '../data/staysFull/staysSlug'

const Reservations = ({ location }) => {
  const search = location && location.search ? JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {}
  const [activeStep, setActiveStep] = useState(0)

  const [myCities, setMyCities] = useState([]) //step 1
  const [myStayTemplates, setMyStayTemplates] = useState([]) // step 2
  const [myStays, setMyStays] = useState([]) // step 3
  
  const [citySlug, setCitySlug] = useState(search.city) // step 1
  const [stayTemplateSlug, setStayTemplateSlug] = useState(search.city ? search.stay : null) // step 2
  const [stayId, setStayId] = useState(null) // step 3
  const [stayEnd, setStayEnd] = useState(null) // step 3
  const [participantId, setParticipantId] = useState(null) // step 4

  useEffect(() => {// step 1
    axios.get(`${process.env.GATSBY_API_URL}/cities`, {
      headers: {
        CotravelingKey: process.env.GATSBY_API_CKEY
      }
    })
    .then(function (response) {
      setMyCities(response.data.datas)
    })
    .catch(function (error) {
      console.log(error)
    })
  }, [])

  useEffect(() => {//step 2
    if (!citySlug) return

    axios.get(`${process.env.GATSBY_API_URL}/stayTemplates?citySlug=${citySlug}`, {
      headers: {
        CotravelingKey: process.env.GATSBY_API_CKEY
      }
    })
    .then(function (response) {
      setMyStayTemplates(response.data.datas)
    })
    .catch(function (error) {
      console.log(error)
    })
  }, [citySlug])

  useEffect(() => {//step 3
    if (!stayTemplateSlug) return

    axios.get(`${process.env.GATSBY_API_URL}/stays?stayTemplateSlug=${stayTemplateSlug}`, {
      headers: {
        CotravelingKey: process.env.GATSBY_API_CKEY
      }
    })
    .then(function (response) {
      setMyStays(response.data.datas)
    })
    .catch(function (error) {
      console.log(error)
    })
  }, [stayTemplateSlug])

  useEffect(() => {//step all
    setActiveStep(participantId ? 4 : stayId ? 3 : stayTemplateSlug ? 2 : citySlug ? 1 : 0)
  }, [citySlug, stayTemplateSlug, stayId, participantId])

  useEffect(() => {//step all
    window && window.scrollTo(0, 0)
  }, [activeStep])

  const steps = [
    {
      label: 'Destination',
      onClick: () => {setCitySlug(null);setStayTemplateSlug(null);setStayId(null);setParticipantId(null);},
    },
    {
      label: 'Séjour',
      onClick: () => {setStayTemplateSlug(null);setStayId(null);setParticipantId(null);},
    },
    {
      label: 'Date',
      onClick: () => {setStayId(null);setParticipantId(null);},
    },
    {
      label: 'Informations',
      onClick: () => {setParticipantId(null);},
    },
    {
      label: 'Paiement'
    }
  ]

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoTraveling - Réservations séjours</title>
        <meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
      </Helmet>
      <div class="container reservations">
        <div class="page_content three-columns">
          <div class="wrap">
            <div class="wrap_float">
              <div class="rtitle">
                <img src={companyDatas.icon} alt={companyDatas.name} style={{height:40,marginRight:10}} />
              <span>{companyDatas.name} Réservation</span>
              </div>
              {activeStep !== 5 ? <Stepper steps={steps} activeStep={activeStep} /> : ''}
              <div class="main">
                {activeStep === 0 ? 
                  <Step1 
                    updateValue={setCitySlug} 
                    myCities={myCities} /> :
                activeStep === 1 ?
                  <Step2 
                    backButton={steps[0].onClick}
                    updateValue={setStayTemplateSlug}
                    myStayTemplates={myStayTemplates} /> :
                activeStep === 2 ?
                  <Step3 
                    backButton={steps[1].onClick} 
                    updateValue={setStayId}
                    updateEnd={setStayEnd}
                    myStays={myStays} /> :
                activeStep === 3 ?
                  <Step4 
                    backButton={steps[2].onClick} 
                    updateValue={setParticipantId}
                    stay={staysSlug[stayTemplateSlug]}
                    city={citiesSlug[citySlug]}
                    stayId={stayId}
                    end={stayEnd} /> :
                  activeStep === 4 ?
                    <Step5 
                      backButton={steps[3].onClick}
                      nextStep={() => setActiveStep(5)}
                      participantId={participantId}
                      stay={staysSlug[stayTemplateSlug]}
                      city={citiesSlug[citySlug]} /> :
                  activeStep === 5 ?
                    <Step6 /> :
                'Error'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Reservations