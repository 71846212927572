import React from 'react'

import axios from 'axios'

import { Grid, FormControl, Button } from '@material-ui/core'
import Paypal from 'gatsby-plugin-paypal'

import arrowDown from '../../assets/img/arrow-down.svg'

const PaiementGrid = ({ stay, participantId, nextStep }) => {
  return (
    <Grid container spacing={3} style={{marginBottom: 20}}>
      <Grid item xs={12}>
        <p style={{fontSize: 20, fontWeight: 'bold'}}>Paiement</p>
      </Grid>
      <Grid item xs={12} style={{marginTop: 20}}>
        <Paypal
          amount={stay.datas.price}
          currency="EUR"
          shippingPreference="NO_SHIPPING"
          onApprove={(data) => {
            const bodyData = {
              participantId,
              orderId: data.orderID,
              facilitatorAccessToken: data.facilitatorAccessToken,
              payerId: data.payerID
            }

            axios.put(`${process.env.GATSBY_API_URL}/participantApprove`, bodyData, {
              headers: {
                CotravelingKey: process.env.GATSBY_API_CKEY
              }
            })
            .then(function () {
              nextStep()
            })
            .catch(function (error) {
              nextStep()
              console.log(error)
              alert('An error occured - 5N')
            })
          }}
          onError={(error) => {
            console.log(error)
            alert('An error occured - 5P')
          }}
        />
      </Grid>
    </Grid>
  )
}

const Step5 = ({ backButton, participantId, stay, city, nextStep }) => {
  return !stay || !city ? <div>Loading...</div> : (
    <div class="rform">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} style={{marginBottom: 20}}>
            <Grid item xs={12} md={4} lg={4} style={{marginTop: 10}}>
              <div class="single-page" style={{padding: '0px 20px'}}>
                <div class="single-sidebar">
                  <div class="tour--info">
                    <div class="top" style={{backgroundImage: `url(${stay.images.hotel[0]})`}}>
                      <div class="tags">
                      </div>
                      <div class="flex-bottom">
                        <div class="rating">
                          <div class="name">{stay.datas.starsName}</div>
                          <div class="stars">
                            {stay.datas.stars > 0 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 1 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 2 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 3 ? <div class="star active" /> : ''}
                            {stay.datas.stars > 4 ? <div class="star active" /> : ''}
                          </div>
                        </div>
                        <div class="_title">{`${city.name}`}</div>
                        <div class="time">{stay.datas.days} jours | {stay.datas.nights} nuits</div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="cost" style={{marginBottom: 0}}>
                        <div class="new-cost" style={{display: 'flex', justifyContent: 'space-between'}}>
                          <span>{stay.datas.price} €</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} lg={8} style={{marginTop: 10}}>
              <PaiementGrid stay={stay} participantId={participantId} nextStep={nextStep} />
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl>
                    <Button onClick={backButton} variant="outlined" color="secondary" class="submitButton">
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                        <img src={arrowDown} alt='arrow-down' style={{transform: 'rotate(90deg)', width: 20, marginRight: 10}} />
                        <span>Informations</span>
                      </div>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Step5
