import React from 'react'

const Step6 = () => {
  return (<div class="step6">
    <h2>Ta réservation est confirmée !</h2>
    <p>Merci pour ta confiance, nous allons t'envoyer un email de confirmation 😁</p>
    <p>Prochainement nous t'ajouterons à un groupe de discussion sur Telegram avec les autres participants à ton séjour pour que vous puissiez faire connaissance.</p>
  </div>)
}

export default Step6
