import React from 'react'

import countriesDatas from '../../data/staysFull/countriesSlug'
import citiesDatas from '../../data/staysFull/citiesSlug'

/*const Step1 = ({ updateValue, countries }) => {
  return (
    <div class="most_popular__section">
      {countries ? countries.map(country => country.cities ? country.cities.map(city => <a key={city.id} class="slider_item" style={{backgroundImage: `url(${city.images.background})`, pointerEvents: city.comingsoon ? 'none' : ''}} type="btn" onClick={() => updateValue(city.id)}>
        {city.comingsoon ? 
        <div class="mslider_info">
          <span>Bientôt disponible</span>
        </div> : ''}
        <div class="slider_item__content">
          <img class="flag" src={country.images.flag} alt={country.name} />
          <div class="title">{city.name}</div>
        </div>
      </a>) : '') : ''}
    </div>
  )
}

export default Step1*/



const Step1 = ({ updateValue, myCities }) => {
  return (
    <div class="most_popular__section">
      {myCities ? myCities.map(city => <a key={city.id} class="slider_item" style={{backgroundImage: `url(${citiesDatas[city.slug].images.background})`, pointerEvents: !city.published ? 'none' : ''}} type="btn" onClick={() => city.published ? updateValue(city.slug) : {}}>
        {!city.published ? 
        <div class="mslider_info">
          <span>Bientôt disponible</span>
        </div> : ''}
        <div class="slider_item__content">
          <img class="flag" src={countriesDatas[city.country.slug].images.flag} alt={city.country.name} />
          <div class="title">{city.name}</div>
        </div>
      </a>) : ''}
    </div>
  )
}

export default Step1