import React from 'react'

import calendar from '../../assets/img/calendar.jpg'
import arrowDown from '../../assets/img/arrow-down.svg'

const days = [
  'Lun.',
  'Mar.',
  'Mer.',
  'Jeu.',
  'Ven.',
  'Sam.',
  'Dim.'
]

const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

const displayDate = (mDate) => {
  const date = new Date(mDate)
  return `${days[date.getDay() - 1]} ${date.getDate() < 10 ? '0' : ''}${date.getDate()} ${months[date.getMonth()]}`
}

const daysDifference = (start, end) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const firstDate = new Date(start)
  const secondDate = new Date(end)

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
}

const Step3 = ({ backButton, updateValue, updateEnd, myStays }) => {
  return (
    <div class="most_popular__section">
      <a class="slider_item rclear" type="btn" onClick={backButton} style={{
        background: '#ffb67f'
      }}>
        <div class="slider_item__content">
          <div class="title">
            Modifier le séjour
            <div class="arrowdown"><img src={arrowDown} alt='arrow-down' style={{transform: 'rotate(90deg)'}} /></div>
          </div>
        </div>
      </a>
      {myStays ? myStays.map(myStay => {
        const days = daysDifference(myStay.startDate, myStay.endDate)
        const nights = days - 1
        const nowDate = new Date()
        nowDate.setDate(nowDate.getDate() + 20)
        const closeReservationDays = daysDifference(myStay.startDate, nowDate)
        const confirmParticipants = myStay.participants.filter(participant => participant.paiementStatus === 'DONE').length

        return closeReservationDays === 0 ? '' : (<a key={myStay.slug} class="slider_item" type="btn" onClick={() => {updateValue(myStay.id);updateEnd(new Date(myStay.endDate))}} style={{
            backgroundImage: `url(${calendar})`,
            pointerEvents: myStay.availablePlaces === 0 ? 'none' : ''
          }}>
            {myStay.availablePlaces === 0 ? 
              <div class="mslider_info">
                <span>Complet</span>
              </div> 
            :
              <div class="tags">
                <div class="tag info">
                  Fermeture des réservations dans {closeReservationDays} jours
                </div>
              </div>
            }
            <div class="slider_item__content">
              <div class="title">
                <div>{displayDate(myStay.startDate)}</div>
                <div class="arrowdown"><img src={arrowDown} alt='arrow-down'/></div>
                <div>{displayDate(myStay.endDate)}</div>
              </div>
              <div class="rfooter">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span>{days} jours | {nights} nuits</span>
                  <span>{myStay.places - confirmParticipants} / {myStay.places} places restantes</span>
                </div>
              </div>
            </div>
          </a>)
      }) : ''}
    </div>
  )
}

export default Step3
